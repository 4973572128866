<script setup lang="ts">
import type {CmsElementText} from "@shopware-pwa/composables-next";
import {useCmsElementConfig} from "@shopware-pwa/composables-next";
import {h} from "vue";
import {CSSProperties} from "vue";
import {decodeHTML} from "entities";
import {getOptionsFromNode} from "~/node_modules/@shopware-pwa/cms-base/helpers/html-to-vue/getOptionsFromNode";
import {renderHtml} from "~/helpers/html-to-vue/renderToHtml";
import {CmsSlot} from "@shopware-pwa/types";

const props = defineProps<{
    content: CmsElementText;
}>();
const context = getCurrentInstance();
const {getConfigValue} = useCmsElementConfig(props.content as CmsSlot & { config: CmsSlot["config"] });

const mappedContent = computed<string>(() => {
    return props.content?.data?.content || getConfigValue("content");
});

const style = computed<CSSProperties>(() => ({
    alignItems: getConfigValue("verticalAlign"),
    wordBreak: 'break-word'
}));

const hasVerticalAlignment = computed(() => !!style.value.alignItems);

const CmsTextRender = () => {
    const config = {
        textTransformer: (text: string) => decodeHTML(text),
        extraComponentsMap: {
            link: {
                conditions(node: any) {
                    return (
                        node.type === "tag" &&
                        node.name === "a" &&
                        !node.attrs?.class?.match(/btn\s?/)
                    );
                },
                renderer(node: any, children: any, createElement: any) {
                    return createElement(
                        "a",
                        {
                            class: "",
                            ...getOptionsFromNode(node).attrs,
                        },
                        [...children]
                    );
                },
            },
            button: {
                conditions(node: any) {
                    return (
                        node.type === "tag" &&
                        node.name === "a" &&
                        node.attrs?.class?.match(/btn\s?/)
                    );
                },
                renderer(node: any, children: any, createElement: any) {
                    const btnClass =
                        "inline-block text-center py-4 px-6 font-semibold focus:outline-none disabled:opacity-75 min-w-[90px] md:min-w-[160px]";
                    const _class = node?.attrs?.class
                        .replace("btn-secondary", `${btnClass} btn-secondary bg-transparent border border-solid border-brand-primary text-brand-primary hover:bg-brand-primary hover:text-white`)
                        .replace("btn-primary", `${btnClass} border border-transparent bg-brand-primary text-white`);

                    return createElement(
                        "a",
                        {
                            class: _class,
                            ...getOptionsFromNode(node).attrs,
                        },
                        [...children]
                    );
                },
            },
        },
    };
    const rawHtml =
        mappedContent.value?.length > 0 ? mappedContent.value : "<div></div>";
    return renderHtml(rawHtml, config, h, context);
};

const isCategoryDescription = computed(() => {
    return props.content.config.content.source === "mapped" && props.content.config.content.value === "category.description";
});
const shortDescription = ref(true);
</script>
<template>
<div
    :class="{'flex items-center': hasVerticalAlignment}"
    :style="style"
>
    <div v-if="isCategoryDescription && content.data.content != ''" class="mb-10">
        <div
            class="relative"
            :class="{'max-h-96 overflow-y-hidden': shortDescription}"
        >
            <div class="overlay" :class="{'hidden': !shortDescription}"></div>
            <CmsTextRender class="w-full"/>
        </div>
        <div class="text-center read-more py-4 text-brand-primary cursor-pointer" @click="shortDescription = !shortDescription">
            <span v-if="shortDescription">{{ $t('general.readDescription') }}</span>
            <span v-if="!shortDescription">{{ $t('general.readLess') }}</span>
        </div>
    </div>

    <CmsTextRender v-else class="w-full"/>
</div>
</template>
